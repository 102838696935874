var render = function render(){
  var _vm$selected$, _vm$filters$selected_, _vm$filters$selected_2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-3 container-expiring"
  }, [_c('product-movement-modal', {
    attrs: {
      "product": (_vm$selected$ = _vm.selected[0]) === null || _vm$selected$ === void 0 ? void 0 : _vm$selected$.product
    }
  }), _c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.filters.selected_categories
    },
    on: {
      "add-categories": _vm.addCategories
    }
  }), _c('div', {
    staticClass: "exp-header"
  }, [_c('e-select', {
    staticClass: "exp-types",
    attrs: {
      "selected_type": "text",
      "options": _vm.types
    },
    model: {
      value: _vm.filters.select,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "select", $$v);
      },
      expression: "filters.select"
    }
  }), _c('e-select', {
    staticClass: "exp-days",
    attrs: {
      "selected_type": "text",
      "options": _vm.days
    },
    model: {
      value: _vm.filters.days,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "days", $$v);
      },
      expression: "filters.days"
    }
  }), _c('div', {
    on: {
      "!click": function ($event) {
        return _vm.showLoadModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "categories-select"
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(_vm._s((_vm$filters$selected_ = _vm.filters.selected_categories) !== null && _vm$filters$selected_ !== void 0 && _vm$filters$selected_.length ? `Выбрано ${(_vm$filters$selected_2 = _vm.filters.selected_categories) === null || _vm$filters$selected_2 === void 0 ? void 0 : _vm$filters$selected_2.length}` : 'Все категории'))]), _c('img', {
    staticStyle: {
      "transform": "rotate(180deg)"
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg"
    }
  })])]), _c('e-select', {
    staticClass: "exp-suppliers",
    attrs: {
      "multiple": "",
      "select_name": "supplier",
      "max_selected_count": 1,
      "max_len_chose": 20,
      "max_selected_count_placeholder": function (val) {
        return '+ Еще ' + val;
      },
      "options": _vm.supplier_list,
      "placeholder": "Все поставщики"
    },
    model: {
      value: _vm.filters.suppliers,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "suppliers", $$v);
      },
      expression: "filters.suppliers"
    }
  }), _c('e-select', {
    staticClass: "exp-storages",
    attrs: {
      "multiple": "",
      "select_name": "storage",
      "max_selected_count": 1,
      "max_len_chose": 20,
      "options": _vm.storage_list,
      "placeholder": "Все места хранения"
    },
    model: {
      value: _vm.filters.storages,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "storages", $$v);
      },
      expression: "filters.storages"
    }
  }), _c('div', {
    staticClass: "clear-text",
    on: {
      "click": _vm.clearFilters
    }
  }, [_vm._v(" Сбросить ")])], 1), _c('div', {
    staticClass: "exp-card"
  }, [_c('div', {
    staticClass: "exp-filters"
  }, [_c('e-input', {
    staticClass: "exp-input",
    model: {
      value: _vm.filters.search,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "search", $$v);
      },
      expression: "filters.search"
    }
  }), _c('b-button', {
    staticClass: "btn-more ml-3",
    on: {
      "click": _vm.openSortModal
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/settings.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "exp-table-block"
  }, [_c('resizable-table', {
    staticClass: "mt-2",
    attrs: {
      "table_name": "expiring",
      "inside_card": false,
      "items": _vm.items,
      "default_fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.select_all
          },
          on: {
            "click": function ($event) {
              _vm.select_all = !_vm.select_all;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.isSelectedItem(item)
          },
          on: {
            "click": function ($event) {
              return _vm.selectRow(item);
            }
          }
        })];
      }
    }, {
      key: "body_expiration_date",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.convertDate(data.value)) + " ")];
      }
    }, {
      key: "body_product",
      fn: function (data) {
        return [_c('product-spec-info', {
          attrs: {
            "product": data.item.product
          }
        })];
      }
    }, {
      key: "body_category",
      fn: function (data) {
        var _data$item$product, _data$item$product$ca;

        return [_c('div', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s((_data$item$product = data.item.product) === null || _data$item$product === void 0 ? void 0 : (_data$item$product$ca = _data$item$product.category) === null || _data$item$product$ca === void 0 ? void 0 : _data$item$product$ca.name) + " ")])];
      }
    }, {
      key: "body_sum",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.formatMoney(data.value)) + " ")];
      }
    }, {
      key: "body_doc",
      fn: function (data) {
        return [_c('div', {
          staticClass: "text-left"
        }, [_c('div', {
          staticClass: "exp-doc",
          on: {
            "click": function ($event) {
              return _vm.goToDoc(data.item.head.id);
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.head.number) + " ")]), _c('div', {
          staticClass: "exp-date"
        }, [_vm._v(_vm._s(_vm.convertDate(data.item.head.date)))])])];
      }
    }])
  }), _c('exp-nav-bar', {
    staticClass: "exp-navbar",
    attrs: {
      "items": _vm.selected
    },
    on: {
      "write_off": _vm.write_off,
      "promo": _vm.promo,
      "show_move": _vm.show_move
    }
  })], 1), _c('pagination', {
    attrs: {
      "total": _vm.total,
      "table_name": "exp-docs"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }