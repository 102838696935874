<template>
  <div
    v-if="items.length > 0"
    class="b-table__navbar"
  >
    <img
      src="/img/icons/table/icon_checked.svg"
      alt=""
      class="cursor"
      @click="clear"
    />
    <span style="white-space: nowrap">{{ items.length }} {{ item_name }} </span>
    <div
      class="b-btns"
      style="margin-top: 0 !important"
    >
      <!--<b-button variant="dark" v-if="items.length === 1">
        Назначить главным группы
      </b-button>
      <b-button variant="dark">Поделиться</b-button>
      <b-button variant="dark">Экспорт</b-button>
       <b-button variant="dark">Печать</b-button>-->
      <b-button
        v-if="!hide_remove"
        variant="dark"
        @click="write_off"
      >
        Списать
      </b-button>
      <b-button
        v-if="!hide_remove"
        variant="dark"
        @click="write_off('return')"
      >
        Возврат
      </b-button>
      <b-button
        v-if="!hide_remove"
        variant="dark"
        @click="promo"
      >
        Создать акцию
      </b-button>

      <template v-if="items.length < 2">
        <b-button
          variant="dark"
          @click="showMovement"
        >
          Показать товародвижение
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'ExpNavBar',
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      items_name: {
        type: String,
        default: ''
      },
      doc_type: {
        type: String,
        default: ''
      },
      clear_selected: Function,
      hide_print: Boolean,
      active_scale: Boolean,
      hide_remove: Boolean,
      scale_active: Boolean,
      movement: {
        type: Boolean,
        default: false
      }
    },

    beforeDestroy() {
      this.clear()
    },

    computed: {
      ...mapGetters({
        product_items: 'products/getCurrentProducts'
      }),
      item_name() {
        if (this.items_name === 'supplier' || this.items_name === 'entity') {
          if (this.items.length === 1) return 'Контрагент'
          if (this.items.length > 1 && this.items.length < 5) return 'Контрагента'
          return 'Контрагентов'
        }

        if (['egais-product', 'product', 'priceup', 'document_product'].includes(this.items_name)) {
          if (this.items.length === 1) return 'Товар'
          if (this.items.length > 1 && this.items.length < 5) return 'Товара'
          return 'Товаров'
        }

        if (this.items_name === 'documents' || this.items_name === 'documents_reval') {
          if (this.items.length === 1) return 'Документ'
          if (this.items.length > 1 && this.items.length < 5) return 'Документа'
          return 'Документов'
        }

        if (this.items_name === 'invoice') {
          if (this.items.length === 1) return 'Документ'
          if (this.items.length > 1 && this.items.length < 5) return 'Документа'
          return 'Документов'
        }

        if (this.items_name === 'cash') {
          if (this.items.length === 1) return 'Позиция'
          if (this.items.length > 1 && this.items.length < 5) return 'Позиции'
          return 'Позиций'
        }
        return 'Товаров'
      }
    },

    methods: {
      ...mapActions({
        setPrintActions: 'products/setPrintActions'
      }),
      editProduct() {
        this.$emit('edit_product')
      },
      removeItem() {
        this.$emit('remove_items', this.items)
      },
      edit() {
        this.$emit('edit', this.items[0])
      },
      cashLoad() {
        this.$emit('cash_load', this.items)
      },
      printDocumentTags() {
        this.setPrintActions({
          print: true
        })
        this.$emit('print_price_tags', this.items?.length ? this.items : this.product_items)
      },
      printLabels() {
        this.setPrintActions({
          print_label: true
        })
        this.$emit('print_price_tags', this.items)
      },

      PriceUp() {
        this.$bvModal.show('modal-price-up')
      },
      CostsAdd() {
        this.$bvModal.show('modal-costs-add')
      },
      Move() {
        this.$emit('product_move')
      },
      createSale() {
        this.$emit('create_sale', this.items[0])
      },
      Copy() {
        this.$emit('copy', this.items[0])
      },
      showMovement() {
        this.$emit('show_move', this.items?.length ? this.items[0] : this.product_items[0])
      },
      clear() {
        if (this.clear_selected) this.clear_selected()
      },
      revaluate(type) {
        this.$emit('revaluate', type)
      },
      write_off(type) {
        this.$emit('write_off', type)
      },
      promo() {
        this.$emit('promo')
      },
      link() {
        this.$emit('link')
      },
      scalesLoad() {
        this.$emit('scalesLoad')
      },
      createIncoming() {
        this.$emit('createIncoming')
      },
      scalesDrop() {
        this.$emit('scalesDrop')
      },
      scalesDisable() {
        this.$emit('disable')
      },
      scalesEnable() {
        this.$emit('enable')
      }
    }
  }
</script>
<style lang="scss" scoped>
  :deep(.navbar-dropdown) {
    .dropdown-menu.show {
      margin-bottom: 18px !important;
    }
  }
</style>
