<template>
  <div class="mt-3 container-expiring">
    <product-movement-modal :product="selected[0]?.product" />
    <categories-load-modal
      :included_categories="filters.selected_categories"
      @add-categories="addCategories"
    />
    <div class="exp-header">
      <e-select
        v-model="filters.select"
        class="exp-types"
        selected_type="text"
        :options="types"
      />
      <e-select
        v-model="filters.days"
        class="exp-days"
        selected_type="text"
        :options="days"
      />
      <div @click.capture="showLoadModal">
        <div class="categories-select">
          <span style="color: black">{{
            filters.selected_categories?.length ? `Выбрано ${filters.selected_categories?.length}` : 'Все категории'
          }}</span>
          <img
            src="/img/icons/arrow_up.svg"
            style="transform: rotate(180deg)"
          />
        </div>
      </div>
      <e-select
        v-model="filters.suppliers"
        multiple
        class="exp-suppliers"
        select_name="supplier"
        :max_selected_count="1"
        :max_len_chose="20"
        :max_selected_count_placeholder="(val) => '+ Еще ' + val"
        :options="supplier_list"
        placeholder="Все поставщики"
      />
      <e-select
        v-model="filters.storages"
        multiple
        class="exp-storages"
        select_name="storage"
        :max_selected_count="1"
        :max_len_chose="20"
        :options="storage_list"
        placeholder="Все места хранения"
      />
      <div
        class="clear-text"
        @click="clearFilters"
      >
        Сбросить
      </div>
    </div>
    <div class="exp-card">
      <div class="exp-filters">
        <e-input
          v-model="filters.search"
          class="exp-input"
        />
        <b-button
          class="btn-more ml-3"
          @click="openSortModal"
        >
          <img
            src="/img/icons/settings.svg"
            alt=""
          />
        </b-button>
      </div>
      <div class="exp-table-block">
        <resizable-table
          class="mt-2"
          table_name="expiring"
          :inside_card="false"
          :items="items"
          :default_fields="fields"
        >
          <template #head_id>
            <e-checkbox
              :checked="select_all"
              @click="select_all = !select_all"
            />
          </template>
          <template #body_id="{ item }">
            <e-checkbox
              :checked="isSelectedItem(item)"
              @click="selectRow(item)"
            />
          </template>
          <template #body_expiration_date="data">
            {{ convertDate(data.value) }}
          </template>
          <template #body_product="data">
            <product-spec-info :product="data.item.product" />
          </template>
          <template #body_category="data">
            <div class="text-left">
              {{ data.item.product?.category?.name }}
            </div>
          </template>
          <template #body_sum="data">
            {{ formatMoney(data.value) }}
          </template>
          <template #body_doc="data">
            <div class="text-left">
              <div
                class="exp-doc"
                @click="goToDoc(data.item.head.id)"
              >
                {{ data.item.head.number }}
              </div>
              <div class="exp-date">{{ convertDate(data.item.head.date) }}</div>
            </div>
          </template>
        </resizable-table>
        <exp-nav-bar
          class="exp-navbar"
          :items="selected"
          @write_off="write_off"
          @promo="promo"
          @show_move="show_move"
        />
      </div>
      <pagination

        :total="total"
        table_name="exp-docs"
        @change_pagination="handlerPagination"
      />
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ResizableTable from '@/components/ResizableTable.vue'
  import formatDate from '@/utils/formatDate'
  import ExpNavBar from '@/views/diagnostics/components/ExpNavBar.vue'
  import { ProductModel } from '@/models/product.model'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal.vue'
  import ProductSpecInfo from '@/views/operational-processes/components/ProductSpecInfo.vue'
  import Pagination from '@/views/shared/components/table/Pagination.vue'
  export default {
    name: 'Expiring',
    components: {
      Pagination,
      ProductSpecInfo,
      CategoriesLoadModal,
      ProductMovementModal,
      ExpNavBar,
      ResizableTable
    },
    apollo: {
      PartAnal: {
        query: require('../gql/PartAnal.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch.id,
              select: this.filters.select[0]?.id,
              supplier: this.suppliers_filter,
              storage: this.storage_filter,
              category: this.category_filter,
              days: this.days_filter,
              search: this.filters.search,
              skip: this.pagination.skip,
              take: this.pagination.take
            }
          }
        },
        result({ data }) {
          this.items = data.PartAnal.list
          this.total = data.PartAnal.total
        }
      },

      Suppliers: {
        query: require('../../products/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data.Suppliers ?? []
        }
      },
      Storages: {
        query: require('../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data?.Storages ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    data() {
      return {
        items: [],
        select_all: false,
        total: 0,
        storage_list: [],
        pagination: {
          skip: 0,
          take: 20
        },
        types: [
          { id: 'expiring', name: 'С подходящим сроком' },
          { id: 'expired', name: 'Уже просрочены' },
          { id: 'unaccounted', name: 'Не контролируются' }
        ],
        days: [
          { id: 'seven_day', name: '7 дней' },
          { id: 'current_week', name: 'Текущая неделя' },
          { id: '30_days', name: '30 дней' },
          { id: 'current_month', name: 'Текущий месяц' }
        ],
        selected: [],
        filters: {
          select: [{ id: 'expiring', name: 'С подходящим сроком' }],
          search: undefined,
          suppliers: [],
          storages: [],
          selected_categories: [],
          days: [{ id: 'seven_day', name: '7 дней' }]
        },
        selectedProduct: new ProductModel(),
        supplier: [],
        supplier_list: [],
        fields: [
          {
            key: 'id',
            label: '',
            width: 45,
            checked: true
          },
          {
            key: 'order',
            label: '№',
            width: 60,
            checked: true
          },
          {
            key: 'product',
            label: 'Наименование',
            width: 300,
            checked: true
          },
          {
            key: 'category',
            label: 'Группа',
            width: 300,
            checked: true
          },
          {
            key: 'expiration_date',
            label: 'Годен до',
            width: 120,
            checked: true
          },
          {
            key: 'remains',
            label: 'Кол-во',
            width: 120,
            checked: true
          },
          {
            key: 'sum',
            label: 'Сумма, ₽',
            width: 120,
            class: 'text-right',
            checked: true
          },
          {
            key: 'doc',
            label: 'Документ',
            width: 120,
            checked: true
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      suppliers_filter() {
        return this.filters.suppliers?.map((el) => el.id)
      },
      storage_filter() {
        return this.filters.storages?.map((el) => el.id)
      },
      category_filter() {
        return this.filters.selected_categories
      },
      days_filter() {
        let date = new Date()
        switch (this.filters.days[0].id) {
          case 'seven_day':
            return 7
          case 'current_week':
            return 7 - [7, 1, 2, 3, 4, 5, 6][date.getDay()]
          case '30_days':
            return 30
          case 'current_month':
            return 30 - date.getDate()
          default:
            return 7
        }
      }
    },
    watch: {
      select_all() {
        if (this.select_all) {
          this.selected = this.items
        } else {
          this.selected = []
        }
      }
    },
    methods: {
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
      },
      clearFilters() {
        this.filters = {
          select: [{ id: 'expiring', name: 'С подходящим сроком' }],
          search: undefined,
          suppliers: [],
          storages: [],
          selected_categories: [],
          days: [{ id: 'seven_day', name: '7 дней' }]
        }
      },
      addCategories(cats) {
        this.filters.selected_categories = cats
      },
      showLoadModal() {
        this.$bvModal.show('categories-modal')
      },
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },
      formatMoney(value) {
        if (!value) value = 0
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB'

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(value)
      },
      openSortModal() {
        this.$bvModal.show('sort-table-modal')
      },
      isSelectedItem(item) {
        return !!this.selected.find((obj) => obj?.product?.id === item?.product?.id)
      },
      selectRow(item) {
        if (this.selected.find((obj) => obj?.product?.id === item?.product?.id)) {
          this.selected = this.selected.filter((obj) => obj?.product?.id !== item?.product?.id)
        } else {
          this.selected.push(item)
        }
      },
      show_move(item) {
        this.selectedProduct = new ProductModel(this.items.find((el) => el.id === item)?.product)
        this.$bvModal.show('product-movement')
      },
      promo() {
        this.createByType({
          variables: {
            input: {
              specs: this.selected.map((item) => {
                const el = this.items.find((el) => el.id === item)
                return {
                  product: el.product.id,
                  price: el.product.purchasePrice,
                  retailPrice: el.product.retailPrice
                }
              }),
              operation: 'promotion',
              branch: this.currentBranch.id
            }
          },
          getRoute: (data) => {
            return {
              name: 'operation-process.overhead.promo.document.edit',
              params: { id: data.CreatePromotionHead.id }
            }
          },
          mutation: require('../gql/CreatePromotionHead.graphql')
        })
      },
      write_off(operation = 'write_off') {
        this.createByType({
          variables: {
            specifications: this.selected.map((item) => {
              return {
                product: item.product.id,
                price: item.product.purchasePrice,
                retailPrice: item.product.retailPrice,
                delta: item.remains
              }
            }),
            branch: this.currentBranch.id,
            operation: operation
          },
          getRoute: (data) => {
            return {
              name: 'operation-process.overhead.outgoing.document.edit',
              params: { id: data.CreateOutgoingDocument.id }
            }
          },
          mutation: require('../gql/CreateOutgoingDocument.gql')
        })
      },
      async createByType(doc) {
        const { data } = await this.$apollo.mutate({
          mutation: doc.mutation,
          variables: doc.variables
        })
        const route = doc.getRoute(data)
        if (route) this.$router.push(route)
      },
      goToDoc(id) {
        this.$router.push({
          name: 'operation-process.overhead.incoming.document.edit',
          params: { id: id }
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .exp-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #ffffff;
    /* Gray/gray150 */

    border: 1px solid #e1e1e1;
    border-radius: 2px;
  }
  .exp-filters {
    padding: 16px;
    display: flex;
  }
  .exp-header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
  }
  .categories-select {
    display: flex;
    flex-direction: row;
    background: white;
    border: 1px solid var(--gray150);
    border-radius: 2px;
    height: 32px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    justify-content: space-between;
    margin-right: 16px;
    width: 200px;
  }
  .exp-types {
    margin-right: 16px;
    min-width: 240px !important;
    width: 240px !important;
  }
  .exp-days {
    margin-right: 16px;
    min-width: 200px !important;
    width: 200px !important;
  }
  .exp-suppliers {
    margin-right: 16px;
    min-width: 300px !important;
    width: 300px !important;
  }
  .exp-storages {
    margin-right: 16px;
    min-width: 300px !important;
    width: 300px !important;
  }
  .exp-input {
    width: 300px;
  }
  .clear-text {
    cursor: pointer;
  }
  .exp-date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #888888;
  }
  .exp-doc {
    cursor: pointer;
    &:hover {
      color: #00a3ff;
    }
  }
  .exp-table-block {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
  }
  .exp-navbar {
    bottom: 5px;
    position: absolute;
    left: 5px;
    right: 5px;
  }
  .container-expiring {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
