var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length > 0 ? _c('div', {
    staticClass: "b-table__navbar"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": "/img/icons/table/icon_checked.svg",
      "alt": ""
    },
    on: {
      "click": _vm.clear
    }
  }), _c('span', {
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(_vm._s(_vm.items.length) + " " + _vm._s(_vm.item_name) + " ")]), _c('div', {
    staticClass: "b-btns",
    staticStyle: {
      "margin-top": "0 !important"
    }
  }, [!_vm.hide_remove ? _c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.write_off
    }
  }, [_vm._v(" Списать ")]) : _vm._e(), !_vm.hide_remove ? _c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": function ($event) {
        return _vm.write_off('return');
      }
    }
  }, [_vm._v(" Возврат ")]) : _vm._e(), !_vm.hide_remove ? _c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.promo
    }
  }, [_vm._v(" Создать акцию ")]) : _vm._e(), _vm.items.length < 2 ? [_c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.showMovement
    }
  }, [_vm._v(" Показать товародвижение ")])] : _vm._e()], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }